import React from "react"

import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import TextWithImage from "../components/text-with-image"
import { Col, Container, Row } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faRoute, faStreetView, faUsers } from "@fortawesome/free-solid-svg-icons"
import Meta from "../components/meta"

const OfferCol = props => (
  <Col>
    <FontAwesomeIcon icon={props.icon} title={props.title} size={"5x"}/>
    <h3>{props.text}</h3>
  </Col>
)

export default ({ data }) => (
  <Layout>
    <Meta title={"Businesscoaching, Trainings und Einzelcoaching"}
          description={"Kampfkunst meets Leadership. Wertebasiertes Coaching mit Bestandteilen des Mentaltraining, NLP & Hypnocoaching sowie dem Karate Dô."}/>
    <div className={"index-page"}>
      <Container className={"section"}>
        <h1>Coaching für Führungskräfte, Business- und Einzelcoaching</h1>
        <Row md={3} sm={1} xs={1} className={"offers"}>
          <OfferCol icon={faRoute} text={"Führungskräfte-Coaching"} title={"Leadership- und Führungskräfte-Coaching"}/>
          <OfferCol icon={faStreetView} text={"Einzelcoaching"}
                    title={"Einzelcoaching bspw. mittels Mentaltraining oder Hypnocoaching"}/>
          <OfferCol icon={faUsers} text={"Workshops und Trainings"}
                    title={"Workshops und Trainings im Business-Kontext"}/>
        </Row>
        <p>
          Dorakami Coaching begleitet Sie in Lebensbereichen, in denen Sie Unterstützung aus der Metaperspektive
          benötigen. Ich stehe Ihnen als Coach zur Seite, erarbeite mit Ihnen gemeinsam die Lösung und unterstütze Sie
          auf dem Weg dorthin.
        </p>
        <p>
          Der Schwerpunkt liegt auf dem Coaching von Führungskräften im Business-Kontext und im persönlichen Bereich,
          sowohl im Team bzw. in der Gruppe als auch im Einzelcoaching.<br/>
          Gern erarbeite ich mit Ihnen individuelle Workshops, Trainings und Vorträge im Rahmen meines Portfolios.
        </p>
        <Link to={"/leistungen"}>Mehr über das Dorakami Coaching-Angebot erfahren</Link>
      </Container>

      <div className={"odd-section angled-top-bottom"}>
        <TextWithImage additionalClassName={"odd-section-inner"} image={data.visionImage.childImageSharp.fixed}
                       alt={"Zielsetzung und Vision im Coaching"}>
          <h2>Mein Coachingverständnis</h2>
          <p>
            Die Sicherstellung eines zielgerichteten Miteinanders basiert im Wesentlichen auf vier Säulen:
          </p>
          <ul className={"bullet-list"}>
            <li>Klare eigene Persönlichkeit</li>
            <li>Spezifische Zieldefinition</li>
            <li>Reflexion der Eigen- und Fremdwahrnehmung</li>
            <li>Präzise Kommunikation</li>
          </ul>
          <p>
            Aus meiner persönlichen Entwicklung heraus hat sich die Einstellung geformt, dass ich Menschen begleite,
            indem ich mit ihnen gehe anstatt sie vor mir her zu treiben. Dabei hat die Entwicklung einer klaren Vision,
            die in ein erreichbares Ziel übertragen wird, einen hohen Stellenwert. Die eindeutige und
            unmissverständliche Kommunikation spielt eine wesentliche Rolle. Im Team umso mehr als in der Arbeit mit
            Einzelpersonen.
          </p>
          <p>
            In beiden Bereichen wende ich die Grundprinzipien des Karate Dô an, um Ihnen darüber die Eigenwahrnehmung
            und Fremdwahrnehmung näher zu bringen. Die Integration von Bewegung, sowohl Indoor als auch Outdoor sowie
            Übungen der Achtsamkeit und der persönlichen Resilienz spielen dabei eine wesentliche Rolle.
          </p>
        </TextWithImage>
      </div>

      <TextWithImage additionalClassName={"section"} image={data.motivationImage.childImageSharp.fixed}
                     alt={"Motivation im Coaching"} reverse={true}>
        <h2>Meine Motivation</h2>
        <p>
          Ich möchte dazu beitragen, dass Menschen in Systemen mehr Verständnis füreinander aufbringen sowie ihre
          eigenen Potenziale erkennen und nutzen. Darüber hinaus sollen sie sich ihrer selbst und ihrer Bedürfnisse
          bewusst werden um sich dadurch ihrer Fesseln entledigen zu können. Dabei liegt es mir am Herzen, dass Sie
          lernen, sich selbst wahrzunehmen und zu erfahren. Ebenso wichtig ist mir auch, dass Sie erfahren, sich in die
          Rolle des gegenüber einzufühlen. Das vermittle ich durch verschiedene Techniken aus dem Karate Dô sowie durch
          das Einbeziehen von Outdoorübungen, Resilienzübungen und Komponenten aus der Achtsamkeit.
        </p>
        <p>
          Das schönste Geschenk ist dabei zu beobachten, wie die Erkenntnisse im Gesicht der Klienten sichtbar werden!
        </p>
        <Link to={"/person"}>Mehr über meine Person erfahren</Link>
      </TextWithImage>
    </div>
  </Layout>
);

export const query = graphql`
  query {
    motivationImage: file(relativePath: { eq: "motivation.jpg" }) {
      childImageSharp {
        fixed(width: 540, height: 540, quality: 90) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    visionImage: file(relativePath: { eq: "vision.jpg" }) {
      childImageSharp {
        fixed(width: 540, height: 620, quality: 90) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`
